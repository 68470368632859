class MissingBlockProperties extends Error {
	readonly name: string;

	/**
	 * Raise from Rail component when a block is invoked from rendering without passing the required properties.
	 * @param name Component/block name
	 * @param message Custom message.
	 */
	constructor(name: string, message?: string) {
		super(message || `${name} properties must be provided from parent component`);
		this.name = name;
	}
}

export default MissingBlockProperties;