import classes from './email-verify.module.scss';
import successImg from '../../assets/imgs/email-confirmed.svg';
import failedImg from '../../assets/imgs/email-failed.svg';
import { Wrapper } from '../../components/shared/wrapper.component';
import { Alert, Button } from 'antd';
import { CaretRightFilled } from '@ant-design/icons';
import useVerifyEmail from '../../Hooks/email-verification.hook';

const EmailConfirm = () => {
  const { state, sendToken } = useVerifyEmail();

  const verifyEmailForm = state.valid
    ? (
      <div className={classes.resetPasswordContainer}>
        <div className={classes.textContainer}>
          <h1 className={classes.title}>Email Verified!</h1>
          <p className={classes.text}>Please login to access your account and Sada Apps</p>
          <Button
            type="primary"
            href="/login"
            className={classes.action}
          >
            Login <CaretRightFilled />
          </Button>
        </div>
      </div>
    ) : (
      <div className={classes.resetPasswordContainer}>
        <div className={classes.textContainer}>
          <h1 className={classes.title}>Email Verification Failed!</h1>
          <p className={classes.text}>It seems your verification token is invalid or expired, click below to receive new token by email</p>
          <Button
            type="primary"
            onClick={sendToken}
            className={classes.action}
            disabled={Boolean(state.tokenResent)}
          >
            Resend token
          </Button>
        </div>
        <br /><br />
        {
          state.tokenResent
          && (
            <Alert
              message={state.tokenResent?.success ? state.tokenResent.msg : state.tokenResent.msg}
              showIcon
              type={state.tokenResent?.success ? "success" : "error"}
              closable
            />
          )
        }
      </div>
    );

  return (
    <Wrapper
      form={verifyEmailForm}
      illustration={{ path: state.valid ? successImg : failedImg, name: 'email-confirmed' }}
      alignIllustration="left"
    />
  );
};

export default EmailConfirm;