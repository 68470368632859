import React from 'react';
import classes from './update-password-form.module.scss';
import { Input, Button, Form, Spin, Alert } from 'antd';
import usePassword from '../../Hooks/password.hook';
import { LoadingOutlined } from '@ant-design/icons';
import { Constants } from '../../constants/constants';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const UpdatePasswordForm = () => {
  const { updateUserPassword, state } = usePassword();

  return (
    <>
      <Form
        name="updatePassword"
        className={classes.updatePasswordForm}
        initialValues={{ remember: true }}
        onFinish={updateUserPassword}
        disabled={state.loading}
        requiredMark={false}
        validateTrigger={['onBlur']}
        colon={false}
      >
        <Form.Item
          className={classes.formItem}
          name="currPassword"
          rules={[
            {
              required: true,
              message: 'Please enter your current password',
            },
          ]}
          label="Current Password"
        >
          <Input.Password size="small" className={classes.inputField} />
        </Form.Item>
        <Form.Item
          className={classes.formItem}
          name="newPassword"
          rules={[
            {
              required: true,
              message: 'Please enter your new password',
            },
            {
              pattern: Constants.PASSWORD_PATTERN,
              message: Constants.PASSWORD_VALIDATION_MESSAGE,
            },
          ]}
          hasFeedback
          label="New Password"
        >
          <Input.Password size="small" className={classes.inputField} />
        </Form.Item>
        <Form.Item
          className={classes.formItem}
          name="confirm"
          label="Confirm new Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your new password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('The two passwords do not match'),
                );
              },
            }),
          ]}
        >
          <Input.Password size="small" className={classes.inputField} />
        </Form.Item>
        <Form.Item className={classes.formItem}>
          <Button
            type="primary"
            htmlType="submit"
            className={classes.submitBtn}
            disabled={state.loading}
          >
            Change Password
          </Button>
          {state.loading && (
            <Spin className={classes.feedback} indicator={antIcon} />
          )}
        </Form.Item>
      </Form>
      {state.type === 'error' && (
        <Alert
          className={classes.feedback}
          showIcon
          message={
            Array.isArray(state.result?.message)
              ? state.result?.message.map((msg, id) => {
                  return <p key={id}>{msg}</p>;
                })
              : state.result?.message
          }
          type="error"
          closable
        />
      )}
    </>
  );
};
