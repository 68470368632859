import { Navigate } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ProfileContextProvider } from './contexts/profile.context';
import { Profile } from './Pages/profile/profile.page';
import { ProtectedRoute } from './components/protected-routes/protected-routes.component';
import LoginPage from './Pages/login/login.page';
import RegisterPage from './Pages/register/register.page';
import ForgotPassword from './Pages/password/forgot-password.page';
import ResetPassword from './Pages/password/reset-password.page';
import EmailConfirm from './Pages/email-verify/email-verify.page';

const reCaptchaSitekey = process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY || '';

function App() {
  return (
    <BrowserRouter basename="/">
      <div className="App">
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSitekey}>
          <ProfileContextProvider>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password-by-token" element={<ResetPassword />} />
              <Route path="/verify-email" element={<EmailConfirm />} />
              <Route path="*" element={<Navigate to="/login" />} />
              <Route element={<ProtectedRoute />}>
                <Route path="profile" element={<Profile />} />
              </Route>
            </Routes>
          </ProfileContextProvider>
        </GoogleReCaptchaProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
