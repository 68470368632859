import { throwHttpError } from './errors';
import accessTokenService from './access-token.service';

class PasswordService {
  API: string;
  accessToken: string;

  constructor() {
    this.API = process.env.REACT_APP_PUBLIC_AUTH_ENDPOINT || '';
    this.accessToken = accessTokenService.get();
  }

  async resetPassword(passwordInfo: IResetPassword): Promise<any> {
    return fetch(`${this.API}/reset-password`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(passwordInfo),
    })
      .then(throwHttpError)
      .then((res) => res.json())
      .then((res: any) => {
        return {
          type: 'success',
          result: { ...res, message: 'Password updated successfully' },
        };
      })
      .catch((err: any) => {
        return {
          type: 'error',
          result: err.res || { message: 'Something went wrong' },
        };
      });
  }

  async requestResetPassword(
    requesetResetPasswordInfo: IRequestResetPassword,
  ): Promise<any> {
    return fetch(`${this.API}/request-reset-password`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requesetResetPasswordInfo),
    })
      .then(throwHttpError)
      .then((res) => res.json())
      .then((res: any) => {
        return {
          type: 'success',
          result: res,
        };
      })
      .catch((err: any) => {
        return {
          type: 'error',
          result: err.res || { message: 'Something went wrong' },
        };
      });
  }

  async resetPasswordByToken(
    resetPasswordByTokenInfo: IResetPasswordByToken,
  ): Promise<any> {
    return fetch(`${this.API}/reset-password-by-token`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resetPasswordByTokenInfo),
    })
      .then(throwHttpError)
      .then((res) => res.json())
      .then((res: any) => {
        return {
          type: 'success',
          result: res,
        };
      })
      .catch((err: any) => {
        return {
          type: 'error',
          result: err.res || { message: 'Something went wrong' },
        };
      });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PasswordService();
