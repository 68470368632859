import { throwHttpError } from "./errors";

class RegisterService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_PUBLIC_AUTH_ENDPOINT || '';
  }

  async register(values: any, reCaptchaToken: string): Promise<any> {
    return fetch(this.API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'recaptcha-token': reCaptchaToken
      },
      body: JSON.stringify(values),
    }).then(throwHttpError)
      .then((res) => res.json())
      .then((res) => {
        return { type: 'success', result: res };
      })
      .catch((err: any) => {
        return { type: 'error', result: err.res || { message: 'Something went wrong' } };
      });
  }

  async resendEmailVerification(email: string): Promise<any> {
    return fetch(`${this.API}/evt?email=${email}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(throwHttpError)
      .then((res) => res.json())
      .catch((err: any) => {
        return { type: 'error', result: err.res || { message: 'Unable to send email verification!' } };
      });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RegisterService();