import { useState } from 'react';
import profileService from '../Services/profile.service';
import { useProfileContext } from '../contexts/profile.context';
import { useForm } from 'antd/es/form/Form';

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
}

interface IState {
  loading: boolean;
  type: string;
  result?: { message?: string[] };
}

const useProfile = () => {
  const [form] = useForm<IUserInfo>();
  const { userInfo } = useProfileContext();
  const [state, setState] = useState<IState>({
    loading: false,
    type: ''
  });

  const handleFieldsChange = (allFields: FieldData[]) => {
    const updatedUserInfo = allFields.reduce((acc: Partial<IUserInfo>, { name, value }) => {
      const fieldName = name;
      acc[fieldName as keyof IUserInfo] = value;
      return acc;
    }, {});

    userInfo.set((prevUserInfo) => ({ ...prevUserInfo, ...updatedUserInfo as IUserInfo }));
  };

  const updateProfileInfo = async (values: IProfileFormValues) => {
    setState((prevState) => ({ ...prevState }));
    const data = await profileService.updateUserInfo(values);
    setState(prevState => ({ ...prevState, ...data, loading: false }));
  };

  return {
    form,
    userInfo,
    state,
    handleFieldsChange,
    updateProfileInfo
  };
};

export default useProfile;
