import { useEffect, useState } from 'react';
import passwordService from '../Services/password.service';
import accessTokenService from '../Services/access-token.service';

interface IState {
  loading: boolean;
  type: string;
  result?: {
    message: string[];
    response?: string;
  };
}

const usePassword = () => {
  const [state, setState] = useState<IState>({
    loading: false,
    type: '',
  });

  useEffect(() => {
    if (state.type === 'success') {
      accessTokenService.remove();
      const loginRoute = `/login?msg=Your password has been reset successfully.&error=false`;
      window.location.href = loginRoute;
    }
  }, [state.type]);

  const updateUserPassword = (values: IPasswordFormValues) => {
    setState((prevState) => ({ ...prevState, loading: true }));

    passwordService
      .resetPassword({
        password: values.newPassword,
        oldPassword: values.currPassword,
      })
      .then((data) => {
        setState((prevState) => ({
          ...prevState,
          ...data,
          loading: false,
        }));
      });
  };

  return { updateUserPassword, state };
};

export default usePassword;
