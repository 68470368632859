import React from 'react';
import { Col, Row } from 'antd';
import classes from './wrapper.module.scss';

type IllustrationAlignment = 'left' | 'right';
interface IProps {
  form?: JSX.Element;
  illustration?: { path: string; name: string };
  alignIllustration?: IllustrationAlignment;
}

export const Wrapper = (props: IProps) => {
  const form = props.form;
  const illustration = props.illustration;
  const align: IllustrationAlignment = props.alignIllustration ?? 'right';

  return align === 'left' ? (
    <Row justify="space-between" style={{ height: '100vh' }}>
      <Col prefixCls={classes.illustrationWrapper}>
        <img
          className={classes.illustration}
          src={illustration?.path}
          alt={illustration?.name}
        ></img>
      </Col>
      <Col prefixCls={classes.formWrapper}>{form}</Col>
    </Row>
  ) : (
    <Row justify="space-between" style={{ height: '100vh' }}>
      <Col prefixCls={classes.formWrapper}>{form}</Col>
      <Col prefixCls={classes.illustrationWrapper}>
        <img
          className={classes.illustration}
          src={illustration?.path}
          alt={illustration?.name}
        ></img>
      </Col>
    </Row>
  );
};
