import { createContext, useContext, useEffect, useState } from "react";
import profileService from "../Services/profile.service";
import accessTokenService from "../Services/access-token.service";
import { message } from "antd";

const ProfileContext = createContext<IProfileContext>(null as any);

interface IProps {
  children: React.ReactNode
}

export const useProfileContext = () => {
  const profileContext = useContext(ProfileContext);

  return profileContext;
};

export const ProfileContextProvider = ({ children }: IProps) => {
  const [userInfo, setUserInfo] = useState<IUserInfo>();

  const fetchUserInfo = async () => {
    profileService.getUserInfo()
      .then((data) => {
        setUserInfo(data.result);
      })
      .catch(() => {
        message.error('Something went wrong while fetching user info')
      });
  };

  useEffect(() => {
    const accessToken = accessTokenService.get();

    if (accessToken) {
      fetchUserInfo();
    }
  }, []);

  const contextValue: IProfileContext = {
    userInfo: { value: userInfo, set: setUserInfo }
  }

  return (
    <ProfileContext.Provider value={contextValue}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContext;
