import { useEffect } from 'react';

/**
 * Sets a title for the document on component mount, on unmount, 
 * the title is set to a default ('Single Sign On')
 * @param title Page title to be set
 */
const useDocumentTitle = (title: string) => {
    const defaultTitle = 'Single Sign On';
    const titleSuffix = 'SADA';
    useEffect(() => {
      document.title = `${title} - ${titleSuffix}`;

        return () => {
            document.title = defaultTitle;
        };
    }, [title]);
};

export default useDocumentTitle;
