import { useCallback, useEffect, useState } from 'react';
import loginService from '../Services/login.service';
import { useSearchParams } from 'react-router-dom';
import accessTokenService from '../Services/access-token.service';
import registerService from '../Services/register.service';
import useTimer from './timer.hook';
import useDocumentTitle from './document-title.hook';

const homePage = process.env.REACT_APP_PUBLIC_HOME_PAGE || '';

interface IState {
  loading: boolean;
  type: string;
  result: { message: string[]; response?: string; accessToken?: string; } | null;
  dest: string;
  msg?: string | null;
  err?: string | null;
  email?: string | null;
  rememberMe: boolean;
}

const useLogin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const msg = searchParams.get('msg');
  const err = searchParams.get('error');
  const dest = searchParams.get('dest');
  const email = searchParams.get('email');
  const finalDest = !!dest ? dest : homePage;
  const [state, setState] = useState<IState>({
    loading: false,
    type: '',
    result: null,
    dest: finalDest,
    msg,
    err,
    email,
    rememberMe: true,
  });
  const [token, setToken] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [resendTimer, resetResendTimer] = useTimer(60);
  useDocumentTitle('Sign In')

  useEffect(() => {
    const accessToken = accessTokenService.get();

    if (!accessToken) {
      return;
    }

    window.location.href = state.dest;
  }, []);

  useEffect(() => {
    setState(prevState => ({ ...prevState, msg, err }));
  }, [searchParams]);

  useEffect(() => {
    if (state.type === 'success') {
      const accessToken = state.result?.accessToken || '';

      if (!accessToken) {
        return;
      }

      try {
        if (state.rememberMe) {
          accessTokenService.set(accessToken, true);
        } else {
          accessTokenService.set(accessToken);
        }

        window.location.href = state.dest;
      } catch (e) { }
    }
  }, [state.type]);

  const onVerify = useCallback(
    (token: string) => {
      setToken(token);
    },
    [setToken],
  );

  const login = (values: any) => {
    if (!token) {
      setRefreshReCaptcha((r) => !r);
      return;
    }

    setState({ ...state, loading: true });
    loginService.login(values, token).then((data) => {
      const formattedData = { ...data, result: { ...data.result, message: [data.result?.message] } };
      setState({
        ...state,
        ...formattedData,
        rememberMe: values.rememberMe,
        loading: false,
      });
      setRefreshReCaptcha((r) => !r);
    });
  };

  const resendEmailVerification = async (email: string) => {
    const res = await registerService.resendEmailVerification(email);

    searchParams.set('msg', res?.message);
    searchParams.set('error', `${!res?.success}`);
    setSearchParams(searchParams);

    if (res.success) {
      resetResendTimer();
    }
  };

  return { login, onVerify, resendEmailVerification, state, refreshReCaptcha, resendTimer };
};

export default useLogin;
