import { useEffect, useState } from "react";

const useTimer = (initValue: number) => {
  const [value, setValue] = useState(initValue);

  useEffect(() => {
    if (value <= 0) {
      return;
    }

    const interval = setTimeout(() => {
      setValue(prevValue => prevValue - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [value]);

  const resetValue = () => {
    setValue(initValue);
  }

  return [value, resetValue] as const;
};

export default useTimer;