import illustrationPath from '../../assets/imgs/sign-in.svg';
import classes from './login.module.scss';
import useLogin from '../../Hooks/login.hook';
import { Alert, Button, Checkbox, Form, Input, Row, Spin } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Wrapper } from '../../components/shared/wrapper.component';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const LoginPage = () => {
  const { login, onVerify, resendEmailVerification, refreshReCaptcha, state, resendTimer } = useLogin();

  const loginForm = (
    <div className={classes.loginContainer}>
      <div className={classes.textContainer}>
        <h1 className={classes.title}>Welcome Back</h1>
        <p className={classes.text}>Please enter your details.</p>
      </div>
      <Form
        name="normal_login"
        validateTrigger={['onBlur']}
        className={classes.loginForm}
        initialValues={{ rememberMe: state.rememberMe }}
        onFinish={login}
        disabled={state.loading}
      >
        <Form.Item
          className={classes.formItem}
          name="username"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input
            className={classes.inputField}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          className={classes.formItem}
          name="password"
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <Input
            className={classes.inputField}
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item className={classes.formItem}>
          <Row justify="space-between">
            <Form.Item
              className={classes.formItem}
              name="rememberMe"
              valuePropName="checked"
              noStyle
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <a className="login-form-forgot" href="/forgot-password">
              Forgot your password?
            </a>
          </Row>
        </Form.Item>
        <Form.Item className={classes.formItem}>
          <Button
            type="primary"
            htmlType="submit"
            className={classes.loginBtn}
            disabled={state.loading}
          >
            Log in
          </Button>
          <p className={classes.signUpText}>
            <span>
              Don't have an account?{' '}
              <a href={`/register?dest=${state.dest}`}>Sign up</a>
            </span>
          </p>
          {state.loading && (
            <Spin className={classes.feedback} indicator={antIcon} />
          )}
        </Form.Item>
        <GoogleReCaptcha
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />
      </Form>
      {state.type === 'error' && (
        <Alert
          className={classes.feedback}
          showIcon
          message={
            Array.isArray(state.result?.message)
              ? state.result?.message.map((msg, id) => {
                return <p key={id}>{msg}</p>;
              })
              : state.result?.message
          }
          type="error"
          closable
        />
      )}
      {state.type !== 'error' && state.err === 'true' && (
        <Alert
          className={classes.feedback}
          showIcon
          message={state.msg}
          type="error"
          closable
        />
      )}
      {state.type !== 'error' && state.err === 'false' && (
        <>
          <Alert
            key={state.msg}
            className={classes.feedback}
            message={state.msg}
            showIcon
            type="success"
            closable
          />
        </>
      )
      }
      {state?.email && <div className={classes.resendContainer}>Didn't get the email?
        <button
          disabled={!!resendTimer}
          onClick={() => { !resendTimer && resendEmailVerification(state?.email as string); }}>
          &nbsp;Request again&nbsp;
        </button>
        {!!resendTimer && `in ${resendTimer}s`}
      </div>}
    </div >
  );

  return (
    <Wrapper
      form={loginForm}
      illustration={{ path: illustrationPath, name: 'login' }}
    />
  );
};

export default LoginPage;
