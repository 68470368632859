import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import jwt from 'jwt-decode';
import registerService from '../Services/register.service';
import profileService from '../Services/profile.service';
import useDocumentTitle from './document-title.hook';

interface IState {
  exp: number;
  valid: boolean;
  sub: string;
  tokenResent?: {
    success: boolean,
    msg: string;
  };
}

const useVerifyEmail = () => {
  const location = useLocation();
  const [state, setState] = useState<IState>({ exp: 0, valid: true, sub: '' })
  useDocumentTitle('Email Confirmation')

  const sendToken = useCallback(async () => {
    try {
      const sub = JSON.parse(state.sub);
      const res = await registerService.resendEmailVerification(sub.email);
      if (res.success) {
        setState({ ...state, tokenResent: { ...res, msg: "Email verification has been sent again!" } });
      } else {
        throw res;
      }
    } catch (error: any) {
      setState({ ...state, tokenResent: { success: false, msg: error.msg || "Unable to send email verification!" } });
    }
  }, [state]);

  const validate = useCallback(async () => {
    try {
      const token = location.search.replace("?token=", "");
      const decoded: IState = jwt(token);

      const res = await profileService.verifyEmail(token);
      if (res?.success) {
        decoded.valid = true;
      } else {
        decoded.valid = false;
      }
      setState(decoded);
    } catch (error) {
      setState({ ...state, valid: false });
    }
  }, [location.search, state]);

  useEffect(() => { validate() }, [location.search]);

  return { state, sendToken };
};

export default useVerifyEmail;
