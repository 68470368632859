import { Outlet, Navigate } from 'react-router-dom';
import accessTokenService from '../../Services/access-token.service';

export const ProtectedRoute = () => {
  const accessToken = accessTokenService.get();

  if (!accessToken) {
    const redirectTo = `/login?dest=${window.location.href}`;
    return <Navigate to={redirectTo} replace />;
  }

  return <Outlet />;
};
  