import { throwHttpError } from './errors';
import accessTokenService from './access-token.service';

class ProfileService {
  API: string;
  accessToken: string;

  constructor() {
    this.API = process.env.REACT_APP_PUBLIC_USER_ENDPOINT || '';
    this.accessToken = accessTokenService.get();
  }

  async getUserInfo(): Promise<IResponse> {
    return fetch(`${this.API}/profile`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(throwHttpError)
      .then((res) => res.json())
      .then((res: IUserInfo) => {
        return { type: 'success', result: res };
      })
      .catch((err: any) => {
        return {
          type: 'error',
          result: err.res || { message: 'Something went wrong' },
        };
      });
  }

  async updateUserInfo(values: IProfileFormValues): Promise<IResponse> {
    return fetch(`${this.API}/profile`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then(throwHttpError)
      .then((res) => res.json())
      .then((res: IUserInfo) => {
        return {
          type: 'success',
          result: { ...res, message: ['Profile updated successfully'] },
        };
      })
      .catch((err: any) => {
        return {
          type: 'error',
          result: err.res || { message: 'Something went wrong' },
        };
      });
  }

  async verifyEmail(token: string): Promise<any> {
    return fetch(`${this.API}/verify-email?token=${token}`)
      .then(throwHttpError)
      .then((res) => res.json())
      .catch((err: any) => {
        return { type: 'error', result: err.res || { message: 'Something went wrong, please try again!' } };
      });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ProfileService();
