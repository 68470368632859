import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useSearchParams } from 'react-router-dom';
import registerService from '../Services/register.service';
import accessTokenService from '../Services/access-token.service';
import useDocumentTitle from './document-title.hook';

const homePage = process.env.REACT_APP_PUBLIC_HOME_PAGE || '';

interface IState {
  loading: boolean;
  type: string;
  result: { message: string[]; response?: string } | null;
  dest: string;
}

const useRegister = () => {
  const [searchParams] = useSearchParams();
  const dest = searchParams.get('dest');
  const finalDest = !!dest ? dest : homePage;
  const [form] = Form.useForm();
  const [state, setState] = useState<IState>({
    loading: false,
    type: '',
    result: null,
    dest: finalDest,
  });
  const [token, setToken] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  useDocumentTitle('Sign Up')

  useEffect(() => {
    const accessToken = accessTokenService.get();

    if (!accessToken) {
      return;
    }

    window.location.href = state.dest;
  }, []);

  useEffect(() => {
    if (state.type === 'success') {
      const loginRoute = `/login?msg=${state.result?.message}&error=false&dist=${state.dest}&email=${form.getFieldValue('email')}`;
      setRefreshReCaptcha((r) => !r);
      window.location.href = loginRoute;
    }
  }, [state.type]);

  const onVerify = useCallback(
    (token: string) => {
      setToken(token);
    },
    [setToken],
  );

  const register = (values: any) => {
    if (!token) {
      setRefreshReCaptcha((r) => !r);
      return;
    }

    setState({ ...state, loading: true, type: '', result: null });
    registerService.register(values, token).then((data) => {
      setState({ ...state, ...data, loading: false });
      setRefreshReCaptcha((r) => !r);
    });
  };

  return { register, onVerify, state, form, refreshReCaptcha };
};

export default useRegister;
