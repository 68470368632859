import { LoginResponse } from "../@types/responses";
import { throwHttpError } from "./errors";

const validateResponse = (res: unknown): res is LoginResponse => {
  if (
    res && typeof res === 'object' &&
    (
      (
        'result' in res && res.result && typeof res.result === 'object' &&
        'message' in res.result && typeof res.result.message === 'string'
      )
      || ('message' in res && typeof res.message === 'string')
    )
  ) {
    return true;
  }
  return false;
};

class LoginService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_PUBLIC_AUTH_ENDPOINT || '';
  }

  async login(values: any, reCaptchaToken: string) {
    return fetch(this.API, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'recaptcha-token': reCaptchaToken
      },
      body: JSON.stringify(values),
    }).then(throwHttpError)
      .then((res) => res.json())
      .then((res) => {
        return { type: 'success', result: res };
      })
      .catch((err: unknown) => {
        if (err && typeof err === 'object' && 'res' in err && validateResponse(err.res)) {
          return { type: 'error', result: err.res };
        } else {
          console.error('Incorrect schema for login response');
          return { type: 'error', result: { message: 'Something went wrong' } };
        }
      });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LoginService();