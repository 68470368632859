import React, { useState } from 'react';
import classes from './profile.module.scss';
import { Menu, MenuProps } from 'antd';
import { UpdateProfileForm } from '../../components/update-profile-form/update-profile-form.component';
import { UpdatePasswordForm } from '../../components/update-password-form/update-password-form.component';
import { ImProfile } from 'react-icons/im';
import { RiLockPasswordLine } from 'react-icons/ri';
import useWindowDimensions from '../../Hooks/window-dimensions.hook';
import useDocumentTitle from '../../Hooks/document-title.hook';

enum settings {
  PROFILE = 'profile',
  PASSWORD = 'password',
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items: MenuItem[] = [
  getItem('Profile', '1', <ImProfile />),
  getItem('Password', '2', <RiLockPasswordLine />),
];

export const Profile = () => {
  const [activeButton, setActiveButton] = useState(settings.PROFILE);
  const { width } = useWindowDimensions();
  useDocumentTitle('My Profile');

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === '1') {
      setActiveButton(settings.PROFILE);
    } else if (e.key === '2') {
      setActiveButton(settings.PASSWORD);
    }
  };

  const updateAccountForms = (
    <div className={classes.wrapper}>
      <div className={classes.menuContainer}>
        <p className={classes.title}>Account Settings</p>
        <Menu
          className={classes.menu}
          onClick={onClick}
          defaultSelectedKeys={['1']}
          mode="inline"
          inlineCollapsed={width <= 1024}
          items={items}
        />
      </div>
      <div className={classes.accountInfoContainer}>
        <div className={classes.formContainer}>
          {activeButton === settings.PROFILE ? (
            <>
              <h3>Personal Information</h3>
              <UpdateProfileForm />
            </>
          ) : (
            <>
              <h3>Password</h3>
              <UpdatePasswordForm />
            </>
          )}
        </div>
      </div>
    </div>
  );

  return updateAccountForms;
};
