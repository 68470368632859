import classes from './update-profile-form.module.scss';
import useProfile from '../../Hooks/profile.hook';

import Avatar from 'react-avatar';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Button, Spin, Alert } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const UpdateProfileForm = () => {
  const { form, userInfo, state, updateProfileInfo, handleFieldsChange } = useProfile();
  const { firstName, lastName } = userInfo.value ?? {};

  return (
    <>
      <Form
        name="updateProfile"
        form={form}
        fields={Object.entries(userInfo.value || {}).map(([key, value]) => ({ name: key, value }))}
        className={classes.updateProfileForm}
        onFinish={updateProfileInfo}
        onFieldsChange={(_, allFields) => { handleFieldsChange(allFields) }}
        disabled={state.loading}
        requiredMark={false}
        colon={false}
        validateTrigger={['onBlur']}
      >
        <div className={classes.avatarContainer}>
          <Avatar
            className={classes.avatar}
            size={150}
            name={`${firstName} ${lastName}`}
            round
            color="#c328b3"
          />
          <div className={classes.userInfoContainer}>
            <Form.Item
              label="First Name"
              className={classes.formItem}
              name="firstName"
              rules={[
                { required: true, message: 'This field cannot be empty' },
              ]}
            >
              <Input allowClear size="large" className={classes.inputField} />
            </Form.Item>
            <Form.Item
              label="Last Name"
              className={classes.formItem}
              name="lastName"
              rules={[
                { required: true, message: 'This field cannot be empty' },
              ]}
            >
              <Input allowClear size="large" className={classes.inputField} />
            </Form.Item>
            <Form.Item label="Email" className={classes.formItem} name="email">
              <Input
                allowClear
                size="large"
                className={classes.inputField}
                disabled
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item className={classes.formItem}>
          <Button
            type="primary"
            htmlType="submit"
            className={classes.submitBtn}
            disabled={state.loading}
          >
            Save Changes
          </Button>
          {state.loading && (
            <Spin className={classes.feedback} indicator={antIcon} />
          )}
        </Form.Item>
      </Form>
      {state.type === 'error' && (
        <Alert
          className={classes.feedback}
          showIcon
          message={
            Array.isArray(state.result?.message)
              ? state.result?.message.map((msg, id) => {
                return <p key={id}>{msg}</p>;
              })
              : state.result?.message
          }
          type="error"
          closable
        />
      )}
      {state.type === 'success' && state.result?.message && (
        <Alert
          className={classes.feedback}
          message={state.result?.message}
          showIcon
          type="success"
          closable
        />
      )}
    </>
  );
};
