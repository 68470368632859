/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Modal } from 'antd';
import classes from './terms-of-services.module.scss';

const TermsOfServicesModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <a onClick={showModal}>Terms of services</a>
      <Modal
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        okText="Close"
        title="Terms Of Services"
        open={isModalOpen}
        onOk={handleClose}
        onCancel={handleClose}
        closable
        className={classes.modal}
        width="1000px"
        centered
      >
        <ol className={classes.list}>
          <li className={classes.listItemTitle}>
            <h3>Registration and Account Security</h3>
            <p className={classes.listItemText}>
              You agree to provide accurate and complete information when
              registering for an account on our web application. You are solely
              responsible for maintaining the security of your account login
              credentials and for all activity that occurs under your account.
            </p>
          </li>
          <li className={classes.listItemTitle}>
            <h3>User Conduct</h3>
            <p className={classes.listItemText}>
              You agree to use our web application only for lawful purposes and
              in compliance with all applicable laws, rules and regulations. You
              shall not use our web application in any way that violates any
              third party rights or any applicable laws.
            </p>
          </li>
          <li className={classes.listItemTitle}>
            <h3>Intellectual Property</h3>
            <p className={classes.listItemText}>
              Our web application and its content, including but not limited to
              text, graphics, logos, images, and software, are the property of
              our company or its licensors and are protected by copyright,
              trademark, patent, trade secret, and other intellectual property
              or proprietary rights laws.
            </p>
          </li>
          <li className={classes.listItemTitle}>
            <h3>Limitations of Liability</h3>
            <p className={classes.listItemText}>
              We will not be liable for any damages or losses arising from your
              use of our web application or your reliance on any information
              provided through our web application. We will not be liable for
              any interruption, delay or failure in providing our web
              application or any content or services contained therein.
            </p>
          </li>
          <li className={classes.listItemTitle}>
            <h3>Termination</h3>
            <p className={classes.listItemText}>
              We reserve the right to terminate your access to our web
              application at any time and for any reason, without prior notice
              or liability.
            </p>
          </li>
          <li className={classes.listItemTitle}>
            <h3>Changes to the Terms</h3>
            <p className={classes.listItemText}>
              We reserve the right to modify these terms of service at any time.
              Your continued use of our web application after any such
              modifications constitutes your acceptance of the new terms.
            </p>
          </li>
        </ol>
      </Modal>
    </>
  );
};

export default TermsOfServicesModal;
